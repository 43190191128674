import { isEmpty, pick } from 'lodash';
import { Types } from 'mongoose';

import {
  CONFIGURABLE_FIELD_DATA_TYPES,
  FIELD_VALIDATION_ERROR_TYPES,
} from '@shared/constants';
import {
  BulkReferenceFieldsSanitizationData,
  BulkReferenceFieldsSanitizationResponse,
  IConfigurableFieldConfig,
  IGenericObject,
  IReference,
} from '@shared/interfaces';

import { isRequired, isUnique } from '../configurable-fields';
import { decryptJSONObject } from '../crypto';

export const sanitizeBulkUploadedConfigurableFields = async (
  convertedData: IGenericObject<string>[],
  allFields: IConfigurableFieldConfig[],
  {
    transformData,
    findReference,
  }: {
    transformData?: boolean;
    findReference?: (
      categoryId: string | Types.ObjectId,
      fieldId: string | Types.ObjectId,
      fieldValue: string
    ) => Promise<IReference>;
  } = {}
) => {
  const payload: BulkReferenceFieldsSanitizationResponse[] = [];

  const requiredFields: string[] = [];
  const uniqueFields: string[] = [];

  allFields.forEach((field) => {
    if (isRequired(field.checkboxes)) requiredFields.push(field.name);
    if (isUnique(field.checkboxes)) uniqueFields.push(field.name);
  });

  const uniqueValues: IGenericObject<Set<string>> = {};
  uniqueFields.forEach((uniqueField) => {
    uniqueValues[uniqueField] = new Set<string>();
  });

  for (const item of convertedData) {
    if (
      Object.entries(item).every(([key, val]) => !isEmpty(key) && isEmpty(val))
    ) {
      continue;
    }

    const errors: IGenericObject<FIELD_VALIDATION_ERROR_TYPES> = {};
    const data: BulkReferenceFieldsSanitizationData = {};

    for (const [fieldName, fieldValue] of Object.entries(item)) {
      const fieldConfig = allFields.find((field) => field.name === fieldName);

      if (!fieldConfig) continue;

      if (!fieldConfig._id) {
        fieldConfig._id = fieldConfig.name;
      }

      data[fieldName] = {
        fieldConfig: pick(fieldConfig, '_id', 'name', 'type', 'checkboxes'),
        fieldValue,
      };

      if (requiredFields.includes(fieldName) && isEmpty(fieldValue)) {
        errors[fieldName] = FIELD_VALIDATION_ERROR_TYPES.REQUIRED;
        continue;
      }

      if (uniqueFields.includes(fieldName)) {
        if (fieldValue !== '' && uniqueValues[fieldName].has(fieldValue)) {
          errors[fieldName] = FIELD_VALIDATION_ERROR_TYPES.UNIQUE;
          continue;
        }
        uniqueValues[fieldName].add(fieldValue);
      }

      switch (fieldConfig.type) {
        case CONFIGURABLE_FIELD_DATA_TYPES.NUMBER: {
          if (isNaN(Number(fieldValue))) {
            errors[fieldName] = FIELD_VALIDATION_ERROR_TYPES.TYPE_MISMATCH;
            continue;
          } else if (transformData) {
            data[fieldName].fieldValue = Number(fieldValue);
          }

          break;
        }
        case CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES: {
          if (fieldValue && transformData && findReference) {
            const referenceTypeFieldConfig =
              fieldConfig.reference_type_field_config;
            if (!referenceTypeFieldConfig) {
              errors[fieldName] = FIELD_VALIDATION_ERROR_TYPES.TYPE_MISMATCH;
            } else {
              const reference = await findReference(
                referenceTypeFieldConfig.category_id,
                referenceTypeFieldConfig.field_id,
                fieldValue
              );

              if (!reference?._id) {
                errors[fieldName] = FIELD_VALIDATION_ERROR_TYPES.TYPE_MISMATCH;
              } else {
                data[fieldName].fieldValue = reference._id;
              }
            }
          }

          break;
        }
        case CONFIGURABLE_FIELD_DATA_TYPES.FORMULA:
          {
            if (fieldValue.substring(0, 12) !== 'ref-formula-') {
              errors[fieldName] = FIELD_VALIDATION_ERROR_TYPES.TYPE_MISMATCH;
              continue;
            } else if (transformData) {
              try {
                const formula = decryptJSONObject(
                  fieldValue.substring(12),
                  fieldName
                );

                if (formula && !formula.error && formula.data) {
                  data[fieldName].fieldValue = JSON.stringify(formula.data);
                } else {
                  errors[fieldName] =
                    FIELD_VALIDATION_ERROR_TYPES.TYPE_MISMATCH;
                  continue;
                }
              } catch {
                errors[fieldName] = FIELD_VALIDATION_ERROR_TYPES.TYPE_MISMATCH;
                continue;
              }
            }
          }

          break;
      }
    }

    const valid = Object.entries(errors).length === 0;

    payload.push({
      valid,
      data,
      errors: valid ? undefined : errors,
    });
  }

  return payload;
};
